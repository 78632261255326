import React from 'react';
import { Button, Modal, Progress, Typography } from 'antd';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { updateAgreedAt } from '../../../services/userAPI';

const { Title } = Typography;
const WordModal = ({ open, onClose }) => {
  const siderCollapsed = useSelector((state) => state.app.siderCollapsed);
  return (
    <Modal
      centered
      classNames={{
        wrapper: classNames(!siderCollapsed && '!ms-72'),
        content: '',
        mask: classNames(!siderCollapsed && '!ms-72'),
      }}
      open={open}
      onOk={() => {}}
      onCancel={() => {}}
      footer={[]}
      closable={false}
      maskClosable={false}
      width={600}
    >
      <div className="text-center">
        <Progress
          /* strokeLinecap="butt" */ percent={100}
          showInfo={false}
          size={['100%', 12]}
          strokeColor="#000"
          trailColor="#eee"
        />
        <div className={classNames('flex mt-4 items-stretch gap-4 flex-col')}>
          <div className={classNames('flex-1 flex flex-col justify-around items-center')}>
            <Title level={3} className="!font-extrabold">
              We Care About Your Safety
            </Title>
            <p className="text-center m-0">
              We noticed you mentioned a topic that may require immediate attention from a licensed professional. While
              Abby is here to provide support and guidance, it’s not a substitute for professional medical or mental
              health care. Your safety and well-being are our highest priorities.
            </p>
            <p className="text-center my-2">
              If you’re feeling overwhelmed, distressed, or are considering harming yourself or others, we strongly urge
              you to seek help from a licensed mental health professional or contact emergency services (e.g., 911). For
              additional support, here are some resources you can reach out to:
            </p>
            <p className="text-left my-2">
              National Suicide Prevention Lifeline (USA): 988 <br />
              Crisis Text Line: Text HOME to 741741 <br />
              International Helplines: Befrienders.org
            </p>
            <p className="text-center my-2">
              By continuing, you acknowledge that Abby is not a crisis service and agree to use it as a supplementary
              tool, not a replacement for professional care, and that you do not plan to harm yourself or others.
            </p>
            <p className="text-center my-0">
              Thank you for choosing Abby. Remember, reaching out to a professional is a strong and courageous step
              forward.
            </p>
            <br />
            <Button
              className={classNames('px-12 font-bold')}
              size="large"
              type="primary"
              onClick={() => {
                onClose();
                updateAgreedAt().finally(() => onClose());
              }}
            >
              I Agree
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default WordModal;
